window.addEventListener('load', () => {
  Utils.init();
  setHeaderEvent();
});

/**
 * 공통함수
 */
const Utils = {
  /**
   * Set default event
   */
  init: () => {
    // Utils.setUploadFile();
    // Utils.setDatepicker();
    // Utils.setTableCheckbox();
    // Utils.setValidation();
    // Utils.setCustomDropdownSelect();
  },
  /**
   * Set File Upload
   */
  setUploadFile() {
    $(document).on('click', '.btn-upload', function() {
      let $file = $(this).parent().find('#idUploadFile');
      $file.trigger('click');
    });
    $(document).on('change', '#idUploadFile', function(e) {
      let $btnFile = $(this).parent().find('.btn-upload');
      let files = e.target.files;

      if(files.length > 0) {
        $btnFile.find('span').text(files[0].name);
      } else {
        $btnFile.find('span').text($btnFile.attr('data-placeholder'));
      }
    })
  },
  /**
   * Set Datepicker
   */
  setDatepicker() {
    let $datepicker = $('.datepicker');
    $datepicker.datepicker({ dateFormat: 'yy년 m월 d일' });
    $datepicker.datepicker().datepicker('setDate', 'today');
  },
  /**
   * Set Tooltip
   */
  setTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      content: function () {
        return $(this).prop('title');
      },
    });
    $('.detail-custom-tab [data-toggle="tab"]').tooltip({
      content: function () {
        return $(this).prop('title');
      },
    });

    $('[data-placement="top"]').tooltip({
      position: {
        my: 'center bottom',
        at: 'center top-8',
      },
    });
    $('[data-placement="right"]').tooltip({
      position: {
        my: 'left center',
        at: 'right+8 center',
      },
    });
    $('[data-placement="bottom"]').tooltip({
      position: {
        my: 'center top',
        at: 'center bottom+8',
      },
    });
    $('[data-placement="left"]').tooltip({
      position: {
        my: 'right center',
        at: 'left-8 center',
      },
    });
  },
  /**
   * Set Table Checkbox
   */
  setTableCheckbox() {
    $(document).on('change', '.ta-table-wrap input.all', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $eachCheckboxList = $tableWrap.find('input.each');
      $eachCheckboxList.prop('checked', $(e.target).prop('checked'));
    });
    $(document).on('change', '.ta-table-wrap input.each', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $allCheckbox = $tableWrap.find('input.all');
      let $eachLength = $tableWrap.find('input.each').length;
      let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  },
  /**
   * Set Validation
   */
  setValidation() {
    let elements = document.querySelectorAll('input, textarea');
    for (let i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity(' ');
        if (!e.target.validity.valid) {
          elements[i].classList.add('is-invalid');
          if (e.target.type == 'file') {
            // label.classList.remove('btn-primary');
            // label.classList.add('btn-danger');
          }
        } else {
          if (e.target.type != 'file') {
            elements[i].classList.remove('is-invalid');
          } else {
            // label.classList.add('btn-primary');
            // label.classList.remove('btn-danger');
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity(' ');
      };
      if ($(elements[i]).attr('required')) {
        elements[i].focusout = () => {
          // elements[i].classList.remove('is-valid');
          elements[i].classList.remove('is-invalid');
          if (elements[i].value == null || elements[i].value == '') {
            elements[i].classList.add('is-invalid');
          } else {
            // elements[i].classList.add('is-valid');
          }
        };
      }
    }
  },
  /**
   * Set Custom Select
   * if Change Select Call setCustomDropdownSelect(id);
   * @param {String} tagId
   */
  setCustomDropdownSelect(tagId = null) {
    const createCustomDropdownSelect = function (el) {
      let $originalSelect = $(el);
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      let placeholder = '선택';
      let isPlaceholder = false;
      if ($(el).parent().find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" ${
          $originalSelect.attr('disabled') ? 'tabindex="-1"' : ''
        } data-toggle="dropdown" aria-expanded="false">
        ${placeholder}
        </button>
        <div class="dropdown-menu" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {
              if ($originalOptionList[j].value == '_placeholder') {
                isPlaceholder = true;
                placeholder = $originalOptionList[j].textContent;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}"
                data-toggle="tooltip" data-placement="bottom"
                title="${$originalOptionList[j].title}">
                ${$originalOptionList[j].text}
                </a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            if ($originalOptionList[i].value == '_placeholder') {
              isPlaceholder = true;
              placeholder = $originalOptionList[i].textContent;
            } else {
              newSelect += `
              <a href="javascript:void(0)"
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-toggle="tooltip" data-placement="bottom"
              data-value="${$originalOptionList[i].value}"
              title="${$originalOptionList[i].title}">
              ${$originalOptionList[i].text}</a>
              `;
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);
        $originalSelect.next().find('.btn').text(placeholder);

        if ($(el).parent().find('a.dropdown-item.active').length > 0) {
          $('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).parent().find('a.dropdown-item.active').text());
        } else {
          if (isPlaceholder) {
            $(el).parent().find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    };
    if (tagId == null) {
      let $customSelect = $('.ta-custom-dropdown-select-wrap select');
      $(document).off('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item');
      $(document).on('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item', function () {
        let $this = $(this);
        let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
        let $originalOptionList = $originalSelect.find('option:not([value="_placeholder"]');
        let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
        let $btn = $this.parents('.custom-dropdown-select').find('.btn');
        $originalOptionList.eq($index).prop('selected', true);
        $originalSelect.trigger('change');
        $btn.text($this.text());
        $btn.parent().removeClass('dropdown-placeholder');
        $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
        $this.addClass('active');
        $originalSelect.removeClass('is-invalid');
        $originalSelect.closest('div').parent().find('.invalid-feedback').remove();
      });
      $customSelect.each((index, el) => {
        createCustomDropdownSelect(el);
      });
    } else {
      let $originalSelect = $(`#${tagId}`);
      $originalSelect.parent().find('.custom-dropdown-select').remove();
      createCustomDropdownSelect($(`#${tagId}`)[0]);
    }
    Utils.setTooltip();
  },
  /**
   * Start Loading
   */
  startLoading() {
    let wrapElmnt = document.querySelector('.spinner-wrap');

    if (wrapElmnt) {
      wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
    } else {
      wrapElmnt = document.createElement('div');
      wrapElmnt.classList.add('spinner-wrap', 'vh-fixed');
      wrapElmnt.dataset.task = 1;
      let loadingElmnt = document.createElement('div');
      loadingElmnt.classList.add('spinner-border');
      let span = document.createElement('span');
      span.classList.add('sr-only');
      span.innerHTML = 'Loading...';
      loadingElmnt.appendChild(span);
      wrapElmnt.appendChild(loadingElmnt);

      let body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
      body.appendChild(wrapElmnt);
    }
  },
  /**
   * End Loading
   */
  endLoading() {
    let wrapElmnt = document.querySelector('.spinner-wrap');
    let taskCount = wrapElmnt.dataset.task;

    if (taskCount > 1) {
      wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
    } else {
      wrapElmnt.style.opacity = 0;
      setTimeout(function () {
        wrapElmnt.remove();
      }, 300);
      let body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'auto';
    }
  },
  /**
   * Ajax
   * ex) Utils.request('GET', 'test.json', null, (data) => { console.log(data) });
   * @param {String} type
   * @param {String} url
   * @param data
   * @param {Function} func
   */
  request(type, url, data, func) {
    $.ajax({
      type,
      url,
      data,
      success: (data) => {
        func(data);
      },
      error: (xhr, status, error) => {
        console.log(`status: ${status}\nmessage: ${error}`);
      },
    });
  },
  /**
   * Number to KRW format
   * ex) 1000000 -> 1,000,000
   * @param {Number} value
   * @returns {String}
   */
  numberFormatter(value) {
    if (value != '' && value != null && typeof value == 'number') {
      value = String(value)
        .replace(/[^\d]+/g, '')
        .replace(/(^0+)/, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    } else {
      value = 0;
    }
    return value === '' ? 'NaN' : value;
  },
  /**
   * Get input[type=file] detail
   * @param {Element} elmnt
   * @returns {Object}
   */
  getFileDetail(elmnt) {
    //파일 경로.
    let filePath = elmnt.value;
    //전체경로를 \ 나눔.
    let filePathSplit = filePath.split('\\');
    // 파일 전체명
    let originalFileName = filePathSplit[filePathSplit.length - 1];
    //파일확장자 앞 .의 index
    let lastDot = originalFileName.lastIndexOf('.');
    //파일명 : .으로 나눈 앞부분
    let fileName = originalFileName.substring(0, lastDot);
    //파일 확장자 : .으로 나눈 뒷부분
    let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
    //파일 크기
    let fileSize = elmnt.files[0].size;

    let object = {
      originalName: originalFileName,
      name: fileName,
      ext: fileExt,
      size: fileSize,
    };

    return object;
  },
  /**
   * Byte to size
   * return ex) 5 GB
   * @param {Number} byte
   * @returns {String}
   */
  byteFormatter(byte) {
    let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (byte == 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
    return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  /**
   * Set date format
   * @param {String} date
   * @returns {Object}
   */
  dateFormatter(date) {
    if ((date == '' || date == null) && typeof date == 'string') {
      return '';
    }
    const addZero = (num, digits) => {
      var zero = '';
      num = num.toString();

      if (num.length < digits) {
        for (var i = 0; i < digits - num.length; i++) {
          zero += '0';
        }
      }
      return zero + num;
    };
    // Safari Invalid Date 로 인한 replace
    date = date.substring(0, date.lastIndexOf('.')).replace(/-/g, '/').replace('T', ' ');
    let newDate = new Date(date);

    let yyyy = newDate.getFullYear();
    let mm = addZero(newDate.getMonth() + 1, 2);
    let m = newDate.getMonth() + 1;
    let dd = addZero(newDate.getDate(), 2);
    let d = newDate.getDate();

    let object = {
      slash: yyyy + '/' + mm + '/' + dd,
      dot: yyyy + '.' + mm + '.' + dd,
      dash: yyyy + '-' + mm + '-' + dd,
      word: yyyy + '년 ' + m + '월 ' + d + '일',
    };

    return object;
  },
};
let isMoved = false;
function setHeaderEvent() {
  const $btnMobileMenu = $('.navbar-nav.mobile a');
  const $mobileMenu = $('.navbar.mobile');
  const $navItem = $('.layout-header .navbar-nav .nav-item');
  const $navLink = $('.navbar-brand, .layout-header .navbar-nav li > a');

  // #region mobile menu event
  $btnMobileMenu.on('click', () => {
    // $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
    $mobileMenu.addClass('active');
    $mobileMenu.addClass('visible');
    $('body').addClass('modal-open');
    // setTimeout(() => {
    //   $('.header-backdrop').addClass('show');
    // }, 100);
  });
  $(document).on('click', '.header-backdrop, .btn-navbar-close', () => {
    // $('.header-backdrop').removeClass('show');
    $mobileMenu.removeClass('active');
    $('body').removeClass('modal-open');
    setTimeout(() => {
      // $('.header-backdrop').remove();
      $mobileMenu.removeClass('visible');
    }, 300);
  });
  // #endregion mobile menu event
  // #region tab event
  $navItem.on('focusin', function () {
    removeMenuActive();
    $(this).addClass('active');
  });
  $navItem.on('click', function () {
    if($(this).find('.btn-menu').length > 0) return;

    $navItem.removeClass('active-only');
    $(`a[data-href="#${$(this).attr('data-href')}"]`).addClass('active-only');
    $(this).removeClass('active');
  });
  $navLink.on('focusin', function () {
    removeMenuActive();
  });
  $(document).on('mousedown', function () {
    removeMenuActive();
  });
  $('.layout-main, .layout-footer').on('focusin', function () {
    removeMenuActive();
  });
  function removeMenuActive() {
    $('.layout-header .navbar-nav .nav-item').removeClass('active');
  }
  // #endregion tab event
  // #region scroll events
  setTimeout(function() {
    $(document).trigger('scroll');
  }, 0);
  $(document).on('scroll', function() {
    let scrollTop = $(this).scrollTop();
    let $sectionList = $('section');

    if(scrollTop > $sectionList.eq(0).outerHeight(true)) {
      $('.side-menu').fadeIn(300);
      $('.scroll-to-top').fadeIn(300);
    } else {
      $('.side-menu').fadeOut(300);
      $('.scroll-to-top').fadeOut(300);
    }
  });
  let interval = null;
  $(document).on('click', '.scroll-to-top', function() {
    if(interval != null) return;

    $('html').animate({scrollTop: 0}, 1000);
    let ms = 0;
    interval = setInterval(function() {
      $('.scroll-to-top').removeClass('progress-' + (ms / 10 - 1));
      $('.scroll-to-top').addClass('progress-' + ms / 10);

      ms = ms + 10;
      if(ms > 1000) {
        clearInterval(interval);
        interval = null;
        $('.scroll-to-top').removeClass('progress-100');
      }
    }, 10);
  });
  // #endregion scroll events
}